@import "variables.less";

.SalesReportMainWrapper{
    display: flex;
    height: 100%;
    .salesReportLeftBlock{
        width: 360px;
        background: @themeLightColor;
        padding: 15px;
        position: relative;
        min-height: 100%;
        height: 100%;
    }
    .salesReportRightBlock{
        width:calc(~"100% - 360px");
        padding: 15px;
    }
}
.salesCaratBlock{
    td{
        text-align: center;
    }
}
.salesReportStockTable{
    overflow: auto;
    height: 100%;
    width: 100%;
    table{
        width: 100%;
        tr{
            th{
                background-color: #f8f8f8;
                padding: 10px;
                border: 1px solid #e8e8e8;
                color: @themeColor;
                text-transform: uppercase;
                text-align: center;
                &:nth-child(1),
                &:nth-child(2){
                    // text-align: left;
                }
                &:nth-child(1){
                    // min-width: 120px;
                }
            }
            td{
                padding: 6px;   
                border: 1px solid #e8e8e8;
                white-space: nowrap;
                cursor: pointer;
                text-align: center;
            }
            .quickSearchTitleTd{
                background-color: #f8f8f8;  
                &:hover{
                    background-color: #f8f8f8;
                }  
            }

        }
        .slaesHead,
        .slaesDetail{
            .salesHeadeInner,
            .salesDetailInner{
                span{
                    height: 40px;
                    line-height: 40px;
                    display: block;
                    text-align: center;
                    position: relative;
                    &:first-child{
                        color: @redColor;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        width: 80%;
                        height: 1px;
                        background-color: #a2a2a2 !important;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: auto;
                    }
                    &:last-child{
                        &:after{
                            content: none;
                        }
                    }
                }
            }
            &.slaesHead{
                span{
                    text-transform: uppercase;
                    font-size: 14px;
                    color: #000;
                    font-weight: 600;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
.redColor{
    color: @redColor;
    font-weight: bold;
}
.blueColor{
    color:@blueColor;
    font-weight: bold;
}
.greenColor{
    color: @greenColor;
    font-weight: bold;
}
.yellowColor{
    color: @yellowColor;
    font-weight: bold;
}
.redColorHover:hover,
.selectTd{
    span{
        color: @redColor; 
        font-weight: bold;
    }
}
.blueColorHover:hover{
    span{
        color:@blueColor;
        font-weight: bold;
    }
}
.greenColorHover:hover{
    span{
        color: @greenColor;
        font-weight: bold;
    }
}
.yellowColorHover:hover{   
    span{
        color: @yellowColor;
        font-weight: bold;
    }
}
.salesDiscountPage{
    .addButton{
        top: -36px;
        padding: 5px 10px;
        right: 10px;
    }
}
.discountWrapper{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    .from-group{
        width: 50%;
        padding: 0 15px;
    }
    .colorPicker{
        position: relative;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        padding-top: 10px;
        .colorSelected{
            margin-left: 10px;
        }
    }
}

.bottomActionReport{
    padding: 0 15px;
    button{
        margin:5px;
    }
}