@import "variables.less";

.documentFileWrapper{
    .documentLeft{
        position: relative;
        .folderAddOption{
            position: absolute;
            bottom: 0;
            background-color: @themeColor;
            color: #fff;
            width: 100%;
            border: none;
            cursor: pointer;
            left: 0;
            padding: 10px 0;
        }
        .documentList{
            padding:0;
            margin: 0;
            li{
                list-style: none;
            }
        }
    }
    .documentRight{
        padding:15px;
        width: calc(~"100% - 250px");
        background-color: @tableBackGroundColor;
        height: 100vh;
        overflow: auto;
        &.active{
            background-color: #fff;
        }
        .mainHeadingTitle {
            font-size: 18px;
            margin-top: 20px;
        }
    }
    .documentWrapper{
        flex-wrap: wrap;
        margin-top: 15px;
        display: grid;
        grid-gap: 0;
        grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
    }
}
.documentListGrid{
    img{
        width:20px;
        height: 20px;
        cursor: pointer;
    }
}
.documentListItem{
    // max-width: 224px;
    // width: calc(~"14% - 10px");
    box-shadow: @thableboxShadow;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin: 5px;
    flex: 1 1 220px;
    .documentListImage{
        height: 150px;
        img{
            width:100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .documentListBottom{
        display: flex;
        align-items: center;
        margin-top: 10px;
        img{
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }
        span{
            font-size: 14px;
            font-weight: 600;
        }
    }
}
.documentNameList{
    display: flex;
    align-items: center;
    img{
        width: 16px;
        height: 16px;
        margin-right:8px;
    }
}
.folderCreate{
    position: absolute;
    bottom:0;
    width: 100%;
    padding: 10px;
    left: 0;
    .inputBlock {
        .inputWrapper{
            input{
                background: transparent;
                border-bottom: 1px solid rgba(0,0,0,0.31);
                padding: 5px 0;
                &::placeholder{
                    font-size: 13px;
                }
            }
        }
    }
    .folderCreateAction{
        button{
            width: 49%;
        }
    }
}
.documentFileUploade{
    position: relative;
    input{
        opacity: 0;
        position: absolute;
        width: 100%;
    }
}
.fileUploadeProcess{
    position: absolute;
    bottom: 0;
    width: 100%;
}
.documentUpload{
    position: fixed;
    right: 50px;
    bottom: 50px;
    .documentUploadeHover{
        position: absolute;
        bottom: 40px;
        right: 0;
        display: none;
        height: 0;
        transition: height 0.2s ease-in-out;
        label{
            list-style: none;
            white-space: nowrap;
            justify-content: flex-end;
            margin-bottom: 10px;
            position: relative;
            input{
                position: absolute;
                width: 100%;
                opacity: 0;
            }
            span{
                background-color: @themeLightColor;
                color: @themeColor;
                padding: 5px;
                display: block;
                font-size: 12px;
            }
            .addDocuemtButton{
                width: 30px;
                height: 30px;
                background-color: @themeColor;    
                border-radius: 100%;
                margin-left: 5px;
                display: flex;
                img{
                    width: 16px;
                    height: 16px;
                    margin:auto;
                }
            }
        }
    }
    &:hover{
        .documentUploadeHover{
            display: block;
            height: auto;
        }
    }
}