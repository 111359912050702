@import 'variables.less';
// menuAccess
.menuAccessWrapper {
  // padding:15px;
  &.settingPage {
    .permissionWrapper {
      height: auto;
    }
  }
  .menuAccessBlock {
    margin-top: 20px;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    position: relative;
    h2 {
      margin-bottom: 0;
      font-size: 16px;
      margin-left: 10px;
      font-weight: 500;
    }
    .mainMenuBlock {
      min-width: fit-content;
      border-radius: 5px;
      margin: 5px;
      height: 35px;
      padding: 5px 6px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border: 1px solid #ddd;
      background-color: #fff;
      font-size: 14px;
      color: #000;
      font-weight: 500;
      position: relative;
      text-transform: capitalize;
      &.active {
        border: 1px solid @themeColor;
        color: @themeColor;
        &::after {
          content: '';
          width: 1px;
          height: 35px;
          background: @themeColor;
          position: absolute;
          bottom: -35px;
          border-radius: 0;
        }
      }
    }
    .menuType {
      background-color: @themeColor;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      color: #fff;
      position: absolute;
      top: -10px;
      left: -10px;
      font-size: 12px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .menuSubAccess {
    border: 1px dashed #e1e1e1;
    padding: 5px 10px;
    margin-top: 30px;
    border-radius: 5px;
    /* box-shadow: 0 0 11px 0px rgb(0 0 0 / 8%); */
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    .submenuacessBlock {
      cursor: pointer;
      margin: 5px 10px;
      text-transform: capitalize;
    }
  }
  .menuInnerBlock {
    margin-top: 20px;
    border: 1px dashed #e1e1e1;
    padding: 15px;
    h2 {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 600;
    }
  }
  .collpaseMenuAccess {
    .customcollapselist,
    .submenuSingle {
      // padding:10px 0;
      border-bottom: 1px solid #e9e9e9;
      border-top: none;
      .accordion__button {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        &:before {
          position: absolute;
          right: 0;
        }
      }
      .accordion__panel {
        padding: 0px;
      }
      .accordingInnerSpace {
        .firstStageBlock {
          padding: 10px 0;
        }
        .subMenuBoxStyle {
          .firstStageBlock {
            padding: 0 10px;
          }
          .customcollapselist {
            border: none;
            .subMenuBoxHeader {
              background: #fafafa;
              padding: 10px 10px 10px 20px;
              border: 1px solid #f3f3f3;
              border-radius: 2px;
              margin-bottom: 5px;
            }
            .accordion__panel {
              padding: 0px;
            }
            .threadStageBlock {
              background: #fafafa;
              margin: 10px;
              padding: 0 15px;
              .forthStageBock {
                .customcollapselist {
                  // padding: 10px 0;
                  background: transparent;
                }
                .forthStageInner {
                  background: #fff;
                  margin-top: 10px;
                  padding: 15px;
                }
              }
            }
          }
        }
      }
    }
    .customcollapselist {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.permissionWrapper .permissionList.submenuSingle .permissionLeft span {
  text-transform: capitalize;
}
.permissionWrapper .permissionHead .permissionLeft {
  margin-right: 10px;
}
