@import "variables.less";

.diamondListinSidebar{
    .ant-drawer-content-wrapper{
        // width: 650px !important;
        background: #fff !important;
        .ant-drawer-wrapper-body{
            background: #fff !important;
            .searchPopupCommon{
                .mainHeadingTitle{
                    margin-bottom: 20px;
                    margin-right: 0;
                }
            }
        }
        .ant-drawer-close{
            background: #fff;
            right: auto;
            left: 0;
            border-radius: 20px;
            top: 8px;
            width: 40px;
            height: 40px;
            line-height: 42px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &.xl-size{
        .ant-drawer-content-wrapper{
            width: 90% !important;
        }
    }
    &.lg-size{
        .ant-drawer-content-wrapper{
            width: 65% !important;
        }
    }
    &.sm-size{
        .ant-drawer-content-wrapper{
            width: 35% !important;
        }
    }
    &.xs-size{
        .ant-drawer-content-wrapper{
            width: 25% !important;
        }
    }
    &.ant-drawer-open{
        .ant-drawer-close{
            left: -58px;
        }
    }
    .sidebarAction{
        position: absolute;
        bottom: 0;
        box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
        background: #fff;
        width: auto;
        padding: 10px;
        left: -15px;
        bottom: -15px;
        right: -15px;
        display: flex;
        justify-content: center;
        .commonButton{
            margin-right: 10px;
        }
    }
}
.popupHalfShow{
    height: calc(~"100vh - 115px");
    .searchResultListWrapper {
        height: 50%;
        .searchInnerResult {
            height: 100%;
        }
        .searchResultTable {
            height: 100% !important;
        }
    }
    .singleStonePlaceOrderDetail{
        height: calc(~"50% - 20px");
        overflow: auto;
    }
    // &.finalCaculation {
    //     .singleStonePlaceOrderDetail{
    //         height: calc(~"25% - 20px");
    //     }
    //     .searchResultListWrapper {
    //         height: 75%;
    //     }
    // }
}
.offerNote {
    width: 100%;
    color: #000;
}
.d-block{
    display: block !important;
}