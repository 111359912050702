@import 'variables.less';

.gx-app-layout.clientPageMain {
  /* background: @themeLightColor !important; */
}
.gx-main-content-wrapper {
  padding: 0;
  height: 100%;
}
.clientInner {
  display: flex;
  height: 100%;
}
.clientRightDetail {
  width: calc(~'100% - 360px');
  position: relative;
}

.clientSearchBox,
.clientSearch {
  display: flex;
}
.clientSearchBox img,
.clientShorting img {
  width: 16px;
  height: 16px;
}
.clientLeftBox {
  width: 360px;
  background: @themeLightColor;
  padding: 0 !important;
  position: relative;
}
.clientTopFilter {
  padding: 15px 15px 0 15px;
  .g-login-btn {
    text-align: right;
  }
}
.clientLeftBox:after {
  content: '';
  width: 20px;
  height: 100%;
  background: @themeLightColor;
  position: absolute;
  top: 0;
  left: -20px;
}
.clientSearch {
  background: @lightthemeBorder;
  padding: 5px 5px 5px 15px;
  border-radius: 10px;
  width: calc(~'100% - 50px');
  margin-right: 10px;
  &.login-search {
    padding: 10px 5px 10px 15px;
    .clientSearchBox {
      width: 100%;
    }
  }
}
.searchTopBlock {
  align-items: center;
}
.clientSearchBox {
  width: calc(~'100% - 40px');
  position: relative;
}
.clientSearchBox input {
  width: 100%;
  border: none;
  background: transparent;
  color: #000;
}
.clientSearchBox input::placeholder {
  color: #000;
}
.searchIcon {
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.clientShorting {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 40px;
}
.clientSearchTop {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
}
.clientSearchLabel {
  font-size: 11px;
  font-weight: 600;
  color: #353438;
  // color: #5e5e8d;
  text-transform: uppercase;
}

.searchClientList .searchClientListItem {
  background-color: @lightCardBg;
  border-radius: 10px;
  position: relative;
  margin: 15px;
  cursor: pointer;
  display: block;
}
.searchClientList .scrollarea-content {
  // height: 100%;
}
.searchClientTop {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid @themeLightColor;
}
.searchClientTop span.diamondType {
  font-size: 12px;
  color: #000;
}
.clientCardDetailItem {
  // width: calc(~"100% / 3");
  width: auto;
}
.nameTitle {
  // max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: auto;
}
.clienrDummay {
  font-size: 12px;
  text-align: right;
}
.clientName {
  font-size: 16px;
  width: calc(~'100% - 70px - 60px');
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.kycStatusItem {
  text-align: center;
  width: 70px;
}
.liveStatusItem {
  text-align: right;
}
.liveStatus {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #39a84c;
  display: block;
  margin-left: 5px;
}
.smallLive {
  width: 6px;
  height: 6px;
}
.liveStatus.live {
  background: #39a84c;
}
.liveStatus.offline {
  background: #ff7885;
}
.kycStatus {
  padding: 5px 13px;
  background: #ecfdf3;
  border-radius: 8px;
  color: #429945;
  font-size: 12px;
  text-transform: uppercase;
}
.kycStatus.active {
  background: #ecfdf3;
  color: #429945;
}
.kycStatus.deactive {
  background: #fdecec;
  color: #d8404e;
}
.kycStatus.onhold {
  background: #fff6fa;
  color: #da49b4;
}
.clientContantName .contactName,
.clientContantName .contactNumber {
  color: #7f7f7f;
}
.clientContantName .contactNumber {
  margin-left: 5px;
}
.clientContantName {
  width: auto;
  display: flex;
  align-items: center;
}
.searchClientBottom {
  font-size: 14px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #a7b1d3;
}
.cardIcon {
  width: 60px;
  display: flex;
  justify-content: flex-end;
}
.cardIcon img {
  width: 18px;
  height: 18px;
  margin-left: 8px;
}
.cardIcon a {
  display: block;
  cursor: pointer;
}
.searchClientListItem {
  display: block;
}
.searchClientListItem:after {
  content: '';
  width: 7px;
  height: 46px;
  background: transparent;
  position: absolute;
  border-radius: 0 10px 10px 0;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
}
.searchClientListItem.active:after {
  background: @themeColor;
}
.searchClientListItem.active {
  background-color: #fff;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.23);
}

.clientRightBoxTop {
  display: flex;
  padding: 10px 15px;
}
.clientRightTopLeft {
  width: calc(~'100% - 150px');
  position: relative;
}
.clientRightTopRight:after {
  content: '';
  width: 1px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  background: #e2e2e2;
}
.clientRightTopLeft .clientDetail {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.clientDetailIdBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.clientRightTopLeft .clientStatus img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.clientRightTopLeft .clientStatus span {
  color: #b8bbc8;
  font-size: 12px;
}
.clientRightTopLeft .clientStatus {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.clientDetailName {
  font-size: 18px;
  margin-right: 10px;
  color: #000;
  text-transform: capitalize;
}
.clientDetailsmallName {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
  color: #000;
}
.clientDetailOutStanding {
  background-color: #defcef;
  padding: 5px 12px;
  border-radius: 10px;
  color: #4ea87e;
  margin-right: 10px;
}
.clientDetailOpenInvoice {
  background-color: #d8e9ff;
  padding: 5px 12px;
  border-radius: 10px;
  color: #096bef;
  margin-right: 10px;
}
.clientDetailstockOnMemo {
  background-color: #ffe5fa;
  padding: 5px 12px;
  border-radius: 10px;
  color: #ea81dd;
  margin-right: 10px;
}
.clientDetailRating {
  background-color: #feface !important;
  color: #d8c700 !important;
  border-radius: 10px;
  padding: 5px 8px;
  margin-right: 10px;
}
.clientDetailId {
  color: #777a84;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}
.clientRightTopRight {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.IconRight {
  margin: 0 10px;
}
.IconRight img {
  width: 18px;
  height: 18px;
}

.footerMainBlock {
  position: absolute;
  bottom: 0;
  max-height: 50px;
  min-height: 50px;
  padding: 10px 15px;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .footerBoxInner {
    width: 100%;
  }
}
.footerBoxItem {
  display: flex;
  align-items: center;
}
.footerBoxItem span:first-child {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #666;
  margin-right: 10px;
  font-weight: bold;
}
.footerBoxItem span:last-child {
  color: #555;
  font-size: 16px;
}
.purchaseDetail {
  display: flex;
  margin-left: 15px;
  align-items: center;
  color: #999;
}
.purchaseDetail img,
.countryLocation img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.countryLocation {
  display: flex;
  align-items: center;
  color: #999;
  margin-right: 10px;
}
.clienrDummay {
  color: #7f7f7f;
}
.listAndDetailicon {
  width: 40px;
  background: @themeColor;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  display: flex;
  cursor: pointer;
}
.listAndDetailicon i {
  font-size: 20px;
  color: #fff;
  height: 100%;
  text-align: center;
  width: 100%;
}
.listAndDetailicon img {
  width: 20px;
  margin: auto;
  height: 20px;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout > .gx-layout-content {
  overflow-x: visible;
}
input:focus {
  outline: none;
}

/* list View */
.listViewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  h2 {
    font-size: 22px;
    margin-right: 10px;
    color: #424242;
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1px;
  }
  p {
    color: #999;
    font-size: 12px;
    margin-bottom: 0;
  }
  .searchTopBlock {
    width: 380px;
  }
  .clientSearch {
    background: @inputLightColor;
    // background: @inputLightColor;
    border: 1px solid @lightthemeBorder;
    .clientShorting {
      background: @themeColor;
    }
  }
}
.listInnerDeatil {
  height: calc(~'100vh - 70px');
  background: @tableBackGroundColor;
  padding: 10px;
  position: relative;
  overflow: auto;
  &:after {
    content: '';
    width: 20px;
    height: 20px;
    background: @tableBackGroundColor;
    position: absolute;
    bottom: 0;
    left: -20px;
  }
  .listItem {
    display: flex;
    // box-shadow: @thableboxShadow;
    background-color: #fff;
    // border: 1px solid @tableBackGroundColor;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 10px;
    .listdetailBox {
      flex-shrink: 0;
      flex-grow: 0;
      width: 320px;
      &.listUserList {
        span:last-child {
          margin-left: 7px;
        }
        .d-flex {
          justify-content: center;
        }
      }
      &.listKycBox {
        text-align: center;
        width: 100px;
      }
      &.listCardAction {
        width: 100px;
        text-align: right;
      }
    }
  }

  .listClientName {
    flex-shrink: 1;
    flex-grow: 1;
    cursor: pointer;
    .listClientNameBox {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }
  .listHeaderMenu {
    background-color: transparent;
    box-shadow: none;
    .listdetailBox,
    .listClientName {
      text-transform: uppercase;
      color: rgba(52, 52, 52, 0.5);
      font-size: 12px;
      font-weight: 600;
    }
    .listUserList {
      text-align: center;
    }
  }
}
.listClientName {
  flex-shrink: 1;
  flex-grow: 1;
  cursor: pointer;
  .listClientNameBox {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
}
.fullRightBlock {
  width: 100%;
  height: 100%;
}
.fullRightBlock .clientRight .listAndDetailicon {
  line-height: 38px;
}
.fullRightBlock .clientRight img {
  width: 24px;
}
.fullRightBlock .clientRight {
  margin-right: 20px;
}
.clientInner .clientRight {
  display: none;
}
.fullRightBlock .clientRightTopLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// kycDetail
.kycFormMainBlock {
  padding: 20px 10px;
  overflow: auto;
  max-height: max-content;
  &.additionInfoBlock {
    padding: 0;
    height: calc(~'100vh - 248px');
    .listInnerDeatil {
      height: calc(~'100vh - 342px');
    }
  }
  .additionTopBlock {
    padding: 10px;
    display: flex;
    .AdditionalTwoBlock {
      width: 50%;
      textarea {
        width: 100%;
      }
    }
  }
  .kycBlockWrapper {
    display: flex;
    flex-wrap: wrap;
    .p-relative {
      position: relative;
      .textAreaWrapper {
        width: 100%;
        padding-right: 0;
      }
      .DayPicker {
        position: absolute;
        left: 0;
        background: #fff;
        z-index: 6;
        border-radius: 6px;
        -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        top: 80%;
        .DayPicker-Month {
          margin: 0;
        }
        .DayPicker-wrapper {
          padding-bottom: 0;
          .DayPicker-Caption {
            background-color: @themeColor;
            margin-bottom: 0;
            padding: 6px 10px;
            border-radius: 5px 5px 0 0;
            > div {
              font-weight: 400;
              font-size: 14px;
              color: #fff;
            }
          }
          .DayPicker-NavButton {
            top: 4px;
            right: 4px;
          }
          .DayPicker-Day--today {
            color: @themeColor;
            .DayPicker-Day--selected {
              color: #fff;
            }
          }
          .DayPicker-Day {
            padding: 4px;
            font-size: 12px;
            width: 28px;
            height: 28px;
            border-radius: 5px;
            line-height: normal;
            display: table-cell;
            &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
              background-color: @themeColor;
              color: #f0f8ff;
            }
          }
        }
      }
    }
    .from-p-w {
      .from-group {
        width: 100%;
        padding-right: 0;
      }
    }
    .commonTextArea {
      margin-top: 0;
      margin-bottom: 0px;
      padding: 2px 0;
    }
    .from-group {
      width: 33.33%;
      padding-right: 20px;
    }
    .full-width-block {
      .from-group {
        width: 100%;
        padding: 0;
      }
    }
    .addRemoveinputBlock {
      .from-group {
        width: 50%;
      }
    }
  }
  .document_table {
    margin-bottom: 20px;
    table {
      tr {
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        border: 1px solid #fafafa;
        text-align: center;
        th {
          background: #fafafa;
          padding: 8px 8px;
        }
        td {
          border-right: 1px solid #f2f2f2;
          padding: 8px 8px;
          line-height: normal;
        }
      }
    }
  }
}

.tabinnerTitle {
  font-size: 22px;
  margin-right: 10px;
  color: #424242;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 1px;
}

.formMainSubBlock {
  border: 1px dashed #e2e2e2;
  padding: 10px;
  .formSubBlockItem {
    width: 50%;
    padding: 0 10px;
  }
}
.formSubTitle {
  margin-bottom: 10px;
  font-weight: 600;
}

.textAreaBox {
  border: 1px solid #e4e4e4;
  height: 50px;
  padding: 10px;
  &:focus {
    outline: none;
  }
}
.inputBlock {
  .ant-calendar-picker {
    width: 100%;
    border: none;
    input {
      border: none;
      border-bottom: 1px solid #e2e2e2;
      border-radius: 0 !important;
      padding: 11px 0;
      height: auto;
      font-size: 14px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &:hover {
      .ant-calendar-picker-input:not(.ant-input-disabled) {
        border-color: @themeColor;
      }
    }
  }
}
.smallLeftClientDetail {
  background: @themeLightColor;
  width: 25px;
  position: relative;
  text-align: center;
  img {
    margin: auto;
    width: 14px;
    height: 14px;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  &:after {
    content: '';
    width: 20px;
    height: 100%;
    background: @themeLightColor;
    position: absolute;
    top: 0;
    left: -20px;
  }
  .smallLeftTitle {
    white-space: nowrap;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    color: @themeColor;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 0.5px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 25px;
  }
}

.fullRightDetail {
  width: calc(~'100% - 25px');
}
.enquiryInner {
  .clientRightBoxTop {
    // border-bottom: 1px solid #efefef;
    .clientRightTopRight {
      align-items: flex-start;
      .listAndDetailicon {
        width: 30px;
        height: 30px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .clientMainTab {
    position: relative;
    min-height: calc(~'100% - 82px');
    .enquiryMainTab {
      position: absolute;
      right: 0;
      top: -46px;
      border-bottom: none;
    }
    .clientMaintabInner {
      .customerSubTab {
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }
  }
  .clientRightTopLeft {
    &:after {
      display: none;
    }
  }
}
.ClientBlockHeight {
  height: 250px;
}
.pb-0 {
  padding-bottom: 0 !important;
}
