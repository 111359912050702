@import 'variables';

@import 'custom';
@import 'Inventory.less';
@import 'client.less';
@import 'offer.less';
@import 'reports.less';
@import 'searchResult.less';
@import 'inventoryConfiguration.less';
@import 'configuration.less';
@import 'diamondDetail.less';
@import 'savedSearch.less';
// @import 'list.less';
@import 'notes.less';
@import 'quickSearch.less';
@import 'notification.less';
@import 'Profile.less';
@import 'contactDirectory.less';
@import 'compare.less';
@import 'OffceSidebar.less';
@import 'settings.less';
@import 'notificationSingle.less';
@import 'dashboard.less';
@import 'document.less';
@import 'menuPermission.less';
@import 'user.less';
@import 'listingSidebar.less';
@import 'transcation.less';
@import 'KYCList.less';
@import 'Shortcut.less';
@import 'TableExpand.less';
@import 'todo.less';
@import 'EmailSnyc.less';
@import '~antd/dist/antd.less';
@import 'dna.less';
@import 'summary-table.less';

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Regular.otf');
  font-weight: 400;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Medium.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/Gilroy-Bold.otf');
  font-weight: 700;
}

body {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-family: 'gilroy';
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #fafafa;
  font-feature-settings: 'tnum', 'tnum';
}

.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
}
.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: @themeColor!important;
}
.grayScroll::-webkit-scrollbar-thumb {
  background: #ddd !important;
}
.grayScroll::-webkit-scrollbar {
  background: #000 !important;
}
.smallGrayScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #a1a1a1 !important;
}
.smallGrayScroll::-webkit-scrollbar-thumb {
  background: #6b6b6b !important;
}
.themeGrayScroll {
  &.scrollarea {
    .scrollbar-container {
      background: #a1a1a1 !important;
      width: 5px;
      opacity: 1;
      z-index: 0;
      .scrollbar {
        margin-left: 0 !important;
        background: #6b6b6b !important;
        width: 5px !important;
      }
    }
  }
}
.smallScroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #fff !important;
}
.grayScroll::-webkit-scrollbar-thumb {
  background-color: #ddd !important;
}

::-webkit-scrollbar
// .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  /* This is more usable for users trying to click it. */
  background-color: #ddd !important;
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb
// .pagination-height .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body::-webkit-scrollbar-thumb
{
  background-color: #2f3589 !important;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  // background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #999 !important;
}

.scrollbar-container.horizontal {
  height: 4px !important;
}
.scrollbar-container.horizontal .scrollbar {
  height: 4px !important;
}

@primary-color: #283895;@secondary-color: #9999ff;@text-color: #818791;@heading-color: #191c27;@nav-dark-bg: #283895;@nav-dark-text-color: #9DDAE9;@header-text-color: #191c27;@layout-header-background: #1b1010;@layout-footer-background: #fffffd;@body-background: #fafafa;@hor-nav-text-color: #fffffd;