@import 'variables.less';

.compareMainPage {
  width: 100vw;
  height: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: #fff;
  padding: 10px;

  .compareTable {
    overflow: auto;
    padding-top: 20px;

    table {
      width: auto;
      position: relative;
      border-spacing: 0;
      tr {
        td {
          border: 1px solid #e8e8e8;
          padding: 10px;
          text-align: left;
          max-width: 180px !important;
          width: 200px;
          min-width: 200px !important;
          max-width: 200px !important;
          &:first-child {
            width: 150px;
            letter-spacing: 0.5px;
            position: absolute;
            left: 0;
            background-color: #f8f8f8;
            border: 1px solid #e8e8e8;
            color: @themeColor;
          }
          &.compareHead {
            position: relative;
            img {
              &.diamondCompareImg {
                width: 130px;
                height: 130px;
                object-fit: contain;
                margin: auto;
              }
            }
            .closeImage {
              position: absolute;
              right: 8px;
              top: 8px;
              width: 14px;
              height: 14px;
              img {
                width: 10px;
                height: 10px;
                display: block;
              }
            }
          }
          &.compareImage {
            text-align: center;
          }
          &:nth-child(2) {
            // padding-left: 160px;
            min-width: 200px !important;
            max-width: 200px !important;
          }
        }
        &:first-child {
          td {
            height: 154px;
            min-height: 154px;
            line-height: 132px;
          }
        }
      }
    }
  }
}
