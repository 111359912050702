@import "./variables.less";

.emailWrapper{
    display: flex;
    height: calc(~"100vh - 46px");
}
.emailRightBox{
    width: calc(~"100% - 360px") !important;
}
.emailBoxWrapper{
    padding: 0 10px;
}
.emailDetailInfomItem{
    display: flex;
    span{
        &:first-child{
            font-weight: 500;
            margin-right: 5px;
        }
    }
}
.emailDetailTop{
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 15px;
    padding-bottom: 15px;
    .clientStatus{
        span{
            color: #454545 !important;
        }
    }
}
.mailDetailBox{
    padding: 15px;
    h2{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    p{
        margin-bottom: 10px;
        color: #898989;
        max-width: 85%;
        font-size: 14px;
        line-height: 25px;
        a{
            color: @themeColor;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
.downloadZipBox{
    margin-top: 30px;
    .downloadZipBoxItem{
        .ant-checkbox-wrapper{
            margin-right: 10px;
        }
        a{
            color: @themeColor;
            font-weight: 600;
            margin-left: 10px;
        }
    }
}
.downloadItem{
    border: 1px solid #ddd;
    width: 250px;
    position: relative;
    margin: 10px 10px 10px 0;
    .downloadItemIcon{
        width: 60px;
        height: 60px;
        display: flex;
        background-color: #eeeeee;
        border-right: 1px solid #ddd;
        img{
            width: 30px;
            height: 30px;
            margin: auto;
        }
    }
    .downloadItemDetail{
        margin-left: 10px;
        width: calc(~"100% - 70px");
        span{
            display: block;
            &.attchmentName{
                font-weight: 600;
                margin-bottom: 3px;
            }
        }
    }
    .downloadImg{
        width: 14px;
        height: 14px;
        position: absolute;
        right: 5px;
        top:5px;
        cursor: pointer;
    }   
}
.dropdownClass{
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    ul{
        padding: 0;
        width: 100px;
        margin: 0;
        li{
            list-style: none;
            font-size: 14px;
            padding: 5px 0;
            font-weight: 500;
            cursor: pointer;
        }
    }
}


// calendar
.calendarMainBoxWrapper{
    background-color: @tableBackGroundColor;
    height: calc(~"100vh - 46px");
}
.calendarTopBox{
    padding: 10px;
    border-bottom: 1px dashed #e1e1e1;
    .calendarDate{
        font-weight: 500;
        font-size: 15px;
        margin-right: 10px;
    }
}
.leftRightArrowBox{
    .leftRightIcon{
        width: 26px;
        height: 26px;
        border: 1px solid #ddd;
        border-radius: 100%;
        margin: 0 5px;
        img{
            margin: auto;
            width: 10px;
            height: 10px;
        }
    }
}
.calendarBoxMain{
    padding: 10px;
}
.calWeekBox,
.calMonthBox{
    background-color: #fff;
    box-shadow: @thableboxShadow;
    padding: 15px;
}
.calWeekTopBox{
    .calWeekDays{
        &:first-child{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 71px;
            min-width: 71px;
            flex: 0;
        }
        flex: 1;
        text-align: left;
        padding: 10px 15px;
        position: relative;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        span{
            display: block;
            width: 100%;
            text-align: center;
            color: #ccc;
            &:first-child{
                font-size: 18px;
                font-weight: 600;
            }
            &:last-child{
                text-transform: uppercase;
                font-weight: 400;
            }
        }
        &.active{
            span{
                color: #000;
            }   
        }
    }
}
.calWeekDashboard{
    display: flex;
    .calWeekTimeSolt{
        width: 70px;
        height: 100%;
        .calWeekTimeSoltItem{
            height: 60px;
        }
    }
    .calWeekDaySolt{
        display: flex;
        flex-grow: 1;
        .calWeekSoltColnum{
            flex-grow: 1;
            border-left: 1px solid #e0e0e0;
            .calWeekSoltColunmItem{
                padding: 2px;
                height: 60px;
                border-top:1px solid #e0e0e0;
                &:last-child{
                    border-bottom: 1px solid #e0e0e0;
                }
            }
            &:last-child{
                border-right: 1px solid #e0e0e0;
            }
        }
    }
}
.calSoltItem{
    position: relative;
    margin-bottom: 2px;
}
.calSetMettingBox{
    background-color: @enquiryLightColor;
    width: 100%;
    padding: 5px;
    position: absolute;
    border-radius: 0 5px 5px 0;
    border-left: 3px solid @enquiryColor;
    height: 100%;
    &.sales{
        background-color: @salesLightColor;
        border-left: 3px solid @salesColor;
    }
    &.kyc{
        background-color: @kycLightColor;
        border-left: 3px solid @kycColor;
    }
    .rowReziseBlock{
        position: absolute;
        bottom: 0;
        cursor: row-resize;
        width: 100%;
        height: 2px;
        left: 0;
    }
    .calSetScheduleTitle{
        font-weight: 600;
        color: #000;
        font-size: 12px;
    }  
    .calSetScheduleTime{
        font-size: 10px;
    } 
    &:after{
        content: "";
        width: 7px;
        height: 46px;
        background: transparent;
        position: absolute;
        border-radius: 0 10px 10px 0;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
    }
}
.calMonthBox{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .calMonthBoxWrapper{
        flex-grow: 1;
        max-height: 100%;
        height: 100%;
        .calMonthDay{
            margin-bottom: -30px;
            height: 30px;
            padding-top: 5px;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 30;
            display: flex;
            .calMonthDayItem{
                flex: 1;
                display: flex;
                text-align: right;
                flex-direction: column;
                align-items: stretch;
                color: #969595;
                font-size: 12px;
                padding: 5px;
                text-transform: uppercase;
                letter-spacing: 0.5px;

            }
        }
        .calMonthDateBox{
            height: 100%;
            border: 1px solid #e1e1e1;
            .calMonthDateItem{
                page-break-inside: avoid;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-self: stretch;
                .calMonthItemBox{
                    display: flex;
                    position: relative;
                    height: 100%;
                    border-bottom: 1px solid #e1e1e1;
                    .calMonthBoxItem{
                        float: left;
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        min-height: 160px;
                        min-width: 14%;
                        flex-grow: 1;
                        flex-basis: 0;
                        background: #fafbfc;
                        border-right: 1px solid #e1e1e1;
                        .calMontDateTitle{
                            text-align: left;
                            // padding: 5px;
                            display: block;
                            color: #454545;
                            width: 30px;
                            height: 30px;
                            border-radius: 100%;
                            text-align: center;
                            margin: 5px;
                            line-height: 30px;
                        }
                        &:last-child{
                            border-right: none;
                        }
                        &.active{
                            .calMontDateTitle{
                                background-color: @themeColor;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }   
}
.scheduleSetBoxMonth{
    padding: 5px;
    margin: 2px;
    border-radius: 0 4px 4px 0;
    background-color: @enquiryLightColor;
    border-left: 2px solid @enquiryColor;
    justify-content: space-between;
    &.enquiry{
        background-color: @enquiryLightColor;
        border-left: 2px solid @enquiryColor;
    }
    &.kyc{
        background-color: @kycLightColor;
        border-left: 2px solid @kycColor;
    }
    &.sales{
        background-color: @salesLightColor;
        border-left: 2px solid @salesColor;
    }
    .monthscedulTime{
        font-size: 10px;
    }
    .monthsceduleTitle{
        font-size: 12px;
        margin-right: 5px;
        font-weight: 500;
    }
}