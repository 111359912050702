@import "variables.less";

.transactionValueBox{
    padding:15px;
    display: flex;
    flex-wrap: wrap;
    .from-group{
        width: 33.33%;
        padding-right: 10px;
    }
}