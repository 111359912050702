@import "variables.less";

.configurationInnerSubTb{
    .subTabInnerDropdown{
        display: none;
    }
}
.caratRangeWrapper{
    // padding: 20px;
    padding-bottom: 50px;
    width: 100%;
    .caratRangeCol{
        display: flex;
        flex-wrap: wrap;
        .from-group{
            width: 100%;
            // padding-right: 20px;
        }
    }
    .formMainSubBlock{
        .from-group{
            width: 33.33%;
            padding-right: 20px;
        }
    }
}
.configActionButton{
    padding:20px 0 10px 0;
    button{ 
        margin-right:10px;
    }
}

.collectionSearchBottom{
    padding: 8px 15px;
    position: sticky;
    .collectionAddOption{
        width: 100%;
        .from-group{
            width: 49%;
            margin-bottom: 0;
            padding-right: 25px;
            display: flex;
            align-items: center;
            label{
                white-space: nowrap;
                width: auto;
            }
            .inputWrapper{
                width: 100%;
                margin-left: 15px;
                input{
                    border: 1px solid #e2e2e2;
                    padding: 10px;
                    border-radius: 5px;
                    height: 35px;
                }
            }
        }
    }
}

.clientActionWrapepr{
    padding: 20px;
    .clientGroupingItem{
        margin-bottom: 20px;
    }
    .clientActionButton{
        display: flex;
        flex-wrap: wrap;
        .clientActionButtonItem{
            width:25%;
            margin-bottom: 20px;
            label{
                margin-bottom: 10px;
                font-size: 15px;
            }
        }
    }
}
.cutGrouping{
    padding: 20px 0;
    .cutGroupLabel{
        display: flex;
        position: relative;
        padding-right:55px;
        .cutGroupLabelItem{
            width: 100%;
            display: flex;
            .from-group{
                width: 33.33%;
                padding-right: 20px;
            }
        }
    }
}


