.summary-table {
  width: 100%;

  &-wrapper {
    overflow-y: auto;
    background-color: #f4f4f8;
    position: relative;

    &-wrapper {
      padding: 0.625rem;
      background-color: #f4f4f8;
    }
  }

  &.sticky-first-column {
    th,
    td {
      &:first-child {
        position: sticky;
        left: 0;
      }
    }
  }

  &.sticky-last-column {
    th,
    td {
      &:last-child {
        position: sticky;
        right: 0;
      }
    }
  }

  th {
    padding: 0.3125rem 0.1875rem;
    font-size: 0.75rem;
    background-color: @tableBackGroundColor;
    color: rgba(52, 52, 52, 0.5);
  }

  td {
    padding: 0.625rem 0.375rem;
    background-color: #fff;
  }

  th,
  td {
    border: none;

    .dark {
      color: #000;
    }
  }

  &.border-right {
    position: absolute;

    ::after {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      width: 1px;
      background-color: #e6e6e6;
    }
  }

  &-size {
    thead {
      > tr:nth-child(1) {
        th:not(:first-child):not(:last-child) {
          border-right: 1px solid #a1a1a1;
        }
      }
      > tr:nth-child(2) {
        th:nth-child(3n + 4):not(:last-child) {
          border-right: 1px solid #a1a1a1;
        }
      }
    }
    tbody {
      > tr {
        td:nth-child(3n + 4):not(:last-child) {
          border-right: 1px solid #afafaf;
        }
      }
    }
  }
}
